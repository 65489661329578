import React, { useEffect, useState, Fragment } from 'react';
import { Col, Row } from 'antd';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { payrollRunClaimCheck } from '@constants/app-constants';
import { accountTypeConst, accountTypes, salaryChannelConst, salaryChannels } from '@constants/';
import ContentLoader from '@sharedComponent/content-loader';
import EdenredButton from '@sharedComponent/button';
import EdenredEmployeesCard from '@sharedComponent/card/employees/employees-card';
import EdenredHeader from '@pages/main-layout/components/main-header';
import EdenredTypo from '@sharedComponent/typography';
import EmployeeHistoryFunctional from '@pages/Leave-Management/leaves-status/Component/employeeHistoryFunctional';
import ImageComponent from '@sharedComponent/image-component';
import PayrollProfile from '@pages/payroll-plus/payroll-profile';
import { HrclaimsArray } from '@constants/';
import { leaveClaimCheck, documentClaimCheck, payrollClaimCheck } from '@constants/app-constants';
import Edenredmodal from '@pages/DesignSystem/Edenredmodal';
import EmployeeService from 'src/services/employee-service';
import ClevertapReact from 'src/utils/clever-tap';
import { icons } from 'src/assets/icons';
import { images } from 'src/assets/img';
import { successToast } from 'src/utils/toast';
import EmployeeProfileInfoCard from './employee-profile-info-card';
import EmployeeProfileHeader from './employee-profile-header';
import EmployeeProfilePopup from '../employee-profile-popup';
import EmployeeProfileIndividualTab from './employee-profile-individual-tab';
import DOMPurify from 'dompurify';

const ViewEmployeePresentational = ({
	handleProfileSave,
	employeeDetailState,
	setEmployeeDetailState,
	employeeDetails,
	buttonDisable,
	profileSaveButtonEnable,
	isEmailValided,
	isShowLoader,
	navigateTabCheck,
	setNavigateTabCheck,
	getEmployeeById,
	bankOptions,
	wpsDetails,
	data,
	isDataLoading,
	employeeDocument,
	employeeHistory,
	sampleData,
	setSampleData,
	leaveTypelist,
	setLeaveTypelist,
	isLeaveEdited,
	employeeProfileCard,
	employeeRoles,
	payrollDetails,
	setPayrollDetails,
	addAllowance,
	setAddAllowance,
	isEnabled,
	setIsEnabled,
	handleDisableProfileButton,
	handleSaveProfileButton,
	buttonFlag,
	setButtonFlag,
	hasErrors,
	setHasErrors,
	empDocState,
	setEmpDocState,
	backupData,
	setBackupData,
	additions,
	setAdditions,
	deductions,
	setDeductions,
	gratuity,
	setGratuity,
	runEmployeeAllowanceList,
	setRunEmployeeAllowanceList,
	employeeProfilePopup,
	setEmployeeProfilePopup,
	handleC3PayToBankAccount,
	loading,
	modalOpen,
	cardloading,
	replacementCardMessage,
	confirmationModelOpen,
	popupVisible,
	popupContent,
	setPopupVisible,
	setPopupContent,
	afterConfirmationOpenModal,
	confirmationsRequest,
	afterOpenModal,
	getDeleterole,
	setModalOpen,
	closePopup,
	employeeCardAddress,
	setInvitePortalEmailId,
	invitePortalEmailId,
	handleInviteEmployeeToPortal,
	notification,
	setNotification,
	expiryDates,
	setExpiryDates,
	imageData,
	setImageData,
	empDocumentForm,
	employeeAllowance,
	setEmpolyeeAllowance,
	empolyeeSalary,
	setEmpolyeeSalary,
	termiation,
	setTerminationType,
	overTimes,
	setOverTimes,
	month,
	setmonth,
	year,
	setyear,
	savePayrollValue,
	setSaveValue,
	gratutiycal,
	setGratuityCal,
	leavingDate,
	setLeavingDate,
	terminationId,
	setTerminationId,
}) => {
	let history = useHistory();
	const { state } = useLocation();
	const [showLeave, setShowLeave] = useState({
		personal: true,
		account: true,
		history: true,
	});
	const [, forceUpdate] = useState();
	const [alertPopup, setAlertPopup] = useState(false);
	let employeeApi = new EmployeeService();
	let currentUser = useSelector((state) => state?.sharedStates?.currentUser);
	// New Team and Permission
	const payrollClaims = (currentUser && currentUser?.payrollClaims) || [];
	const hrClaims = (currentUser && currentUser?.hrClaims) || [];
	//
	let newHRClaims = [];
	let leavesTab = [];
	let documentsTab = [];
	let payrollTab = [];
	payrollClaims.map((item) => {
		if (HrclaimsArray?.includes(item?.value)) {
			newHRClaims.push(item);
		}
	});
	hrClaims.map((item) => {
		if (HrclaimsArray?.includes(item?.value)) {
			newHRClaims.push(item);
		}
	});
	let newclaim = newHRClaims?.map((item) => {
		if (leaveClaimCheck.includes(item?.value)) {
			leavesTab.push(item);
		} else if (documentClaimCheck?.includes(item?.value)) {
			documentsTab.push(item);
		}
	});
	let newclaim1 = payrollClaims?.map((item) => {
		if (payrollRunClaimCheck?.includes(item?.value)) {
			payrollTab.push(item);
		}
	});
	// profile
	const nPayrollClaims = [];
	let newClaims = payrollClaims?.map((item) => {
		if (payrollClaimCheck?.includes(item?.value)) {
			nPayrollClaims.push(item);
		}
	});
	const cardcontent = [
		{
			key: 1,
			icon: icons.focus_state.AddCard,
			label: 'Order Card Replacement',
		},
		{
			key: 2,
			icon: icons.focus_state.Warning,
			label: 'ATM Dispute',
		},
		{
			key: 4,
			icon: icons.focus_state.CardStatement,
			label: 'Request Card Statement',
		},
	];

	async function updateWPSDetails(values) {
		try {
			let response = await employeeApi.updateWPS(values, employeeDetails?.id);
			if (response && response?.status === 200) {
				if (response?.data && response?.data?.message) {
					successToast({
						msg: response.data.message,
						icon: 'Error',
					});
				} else {
					successToast({
						msg: 'WPS Details Updated successfully',
						icon: 'Mail',
					});
				}
			}
		} catch (err) {}
	}
	const openDeleteModal = () => {
		setEmployeeProfilePopup({
			...employeeProfilePopup,
			isPopupVisible: true,
			okText: 'Yes',
			cancelText: 'No',
			popupMessage: 'Do you want to delete this employee?',
			popupSubMessage: 'Their card will be deleted and you can no longer run payroll for them',
			param: 'deleteEmployee',
		});
	};
	const handleProfileCard = (name, param) => {
		if (param !== 'c3payToBank') {
			if (param !== 'cardOrder') {
				handleClick({ label: name });
			} else {
				if (!employeeDetails.requestReplacementCardButtonEnabledState) {
					let { title = '', content = ''} = employeeDetails?.requestReplacementCardMessage ?? {};
					if(!title && !content) {
						title = `Unable to Process Card Replacement. \n Please Try Again Later.`
						content = `If the issue persists, please contact us at <a href="mailto:edenredsupport.ae">edenredsupport.ae</a> for assistance.`
					}
					setPopupVisible(true);
					setPopupContent({
						title: title,
						content: <div className='card_replacement_message' dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(content) }} />,
						onOkayButton: 'Understood'
					});
			} else {
				setModalOpen(true);
				}
			}
		} else {
			setEmployeeProfilePopup({
				...employeeProfilePopup,
				param: 'c3PatToBank',
				okText: 'Yes, Delete',
				cancelText: 'Go back',
				isPopupVisible: true,
				popupMessage: 'Employee’s card will be deleted',
				popupSubMessage:
					'They will be registered with a bank account.You may be charged a small fee for card deletion, depending upon your agreement.Do you confirm?',
			});
		}
	};
	const handleClick = (value) => {
		let data = value.label;
		if (data === 'Order Card Replacement') {
			if (employeeDetails.requestReplacementCardButtonEnabledState) {
			ClevertapReact.event('ReplacementCard');
			history.push({ pathname: '/employees/replacement_card', state: { ...state } });
			} else {
				setPopupVisible(true);
				let { title = '', content = '' } = employeeDetails?.requestReplacementCardMessage ?? {};
				if(!title && !content) {
					title = `Unable to Process Card Replacement. \n Please Try Again Later.`
					content = `If the issue persists, please contact us at <a href="mailto:edenredsupport.ae">edenredsupport.ae</a> for assistance.`
				}
				setPopupContent({
					title: title,
					content: <div className='card_replacement_message' dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(content) }} />,
					onOkayButton: 'Understood'
				});
			}
		} else if (data === 'ATM Dispute') {
			ClevertapReact.event('ATMDispute');
			history.push({ pathname: '/employees/transaction-detail', state: { ...state } });
		} else if (data === 'Change to Bank Account') {
			ClevertapReact.event('ChangetoBank Account');
			history.push({ pathname: '/employees/changeBankAccount', state: { ...state } });
		} else if (data === 'Request Card Statement') {
			ClevertapReact.event('RequestCardStatement');
			history.push({ pathname: '/employees/requestStatement', state: { ...state } });
		}
	};

	const handleDocument = () => {
		// history.push({ pathname: '/employees/employee-document', state: { ...state } });
	};

	const handleRequestCardholder = () => history.push({ pathname: '/employees/requestStatement', state: { ...state } });

	const handleViewSalaryHistory = () =>
		history.push({
			pathname: '/employees/view-salary-history',
			state: { ...state, employeeHistory: employeeHistory, data },
		});

	const salaryChannel = (employeeDetails && employeeDetails?.salaryChannel) || '';

	const salaryChannelDescription = (employeeDetails && employeeDetails?.salaryChannelDescription) || '';

	const getAccountType = () => accountTypes?.filter((accountType) => salaryChannel?.toLowerCase()?.includes(accountType?.toLowerCase())) || [];

	const getSalaryChannelType = () =>
		Object.assign({}, ...salaryChannels?.filter((payroll) => salaryChannelDescription?.toLowerCase()?.includes(payroll?.channel?.toLowerCase())))
			.value || '';

	const currentSalaryChannel = getSalaryChannelType();
	const accountType = getAccountType()?.length && getAccountType()?.[0];
	const SHOW_EDIT = currentSalaryChannel === salaryChannelConst?.Active;
	const SHOW_DELETE = currentSalaryChannel === salaryChannelConst?.Active || currentSalaryChannel === salaryChannelConst?.Pending;

	useEffect(() => {
		if (history?.location?.state?.selectedTab === '3' || history?.location?.state?.selectedTab === '1') {
			ClevertapReact.event('Profile_DocsPage');
		}
		setNavigateTabCheck({ ...navigateTabCheck, currentTab: history?.location?.state?.selectedTab });
	}, [history?.location?.state?.selectedTab]);

	useEffect(() => {
		if (history?.location?.state && history?.location?.state?.response) {
			setPopupVisible(true);
			setPopupContent({
				title: null,
				content: history?.location?.state?.response,
			});
		}
		setTimeout(() => {
			if (history.location && history.location.state && history.location.state.response) {
				const state = { ...history.location.state };
				delete state.response;
				history.replace({ ...history.location, state });
			}
		}, 0);
		forceUpdate({});
	}, []);
	const handleAlertPopup = (value) => {
		setAlertPopup(false);
		setNavigateTabCheck({
			...navigateTabCheck,
			isTabClicked: false,
			currentTab: value === 'handleAlertPopUp' ? navigateTabCheck?.selectedTab : navigateTabCheck?.tabKey,
		});
		getEmployeeById(employeeDetails?.id);
	};
	return (
		<div xl={24} md={24} lg={24} sm={24} xs={24} style={{ display: 'flex', height: '100%', width: '100%', justifyContent: 'center' }}>
			<Edenredmodal isOpen={popupVisible} textcenter={true} title={popupContent?.title} desc={popupContent?.content} onOkay={closePopup} onOkayButton={popupContent?.onOkayButton} />
			<EdenredHeader edit />
			{isDataLoading && <ContentLoader />}
			{/* web */}

			{!isDataLoading && (
				<div className="view-employees-container">
					<EmployeeProfileHeader
						{...{
							history,
							nPayrollClaims,
							isShowLoader,
							handleProfileSave,
							buttonDisable,
							profileSaveButtonEnable,
							employeeDetailState,
							isEmailValided,
							isLeaveEdited,
							employeeDetails,
							openDeleteModal,
							handleSaveProfileButton,
							navigateTabCheck,
							handleDisableProfileButton,
						}}
					/>
					<div style={{ display: 'flex', flexDirection: 'row', gap: '24px' }}>
						<div className="view-employee view-employee-profile">
							{/* <Col className="view-employee" style={{ width: '287px' }}> */}
							<EmployeeProfileInfoCard
								{...{ employeeDetails, history, employeeRoles, accountType, employeeProfileCard, handleProfileCard }}
							/>
						</div>
						<div className="view-employee-tabs view-employee-tab-profile">
							{/* <Col className="view-employee-tabs" style={{ width: '809px' }}> */}
							{history?.location?.state?.mainProfile ? (
								<>
									{showLeave?.history ? (
										<Col xl={24} md={24} lg={24} sm={24} xs={24}>
											<PayrollProfile
												{...{
													handleClick,
													openDeleteModal,
													employeeDetails,
													updateWPSDetails,
													getEmployeeById,
													accountType,
													bankOptions,
													claims: payrollClaims,
													mainProfile: history?.location?.state?.mainProfile,
													setShowLeave,
													showLeave,
													employeeDetailState,
													setEmployeeDetailState,
												}}
											/>
										</Col>
									) : null}
									{showLeave?.personal && showLeave?.account ? (
										nPayrollClaims?.length > 0 && employeeDetails?.employeeId ? (
											<Col xl={24} md={24} lg={24} sm={24} xs={24}>
												<EmployeeHistoryFunctional
													{...{
														employeeDetails: employeeDetails,
														claims: hrClaims,
														setShowLeave: setShowLeave,
														showLeave: showLeave,
														sampleData,
														setSampleData,
														leaveTypelist,
														setLeaveTypelist,
													}}
												/>
											</Col>
										) : null
									) : null}
								</>
							) : (
								<EmployeeProfileIndividualTab
									{...{
										navigateTabCheck,
										handleClick,
										openDeleteModal,
										employeeDetails,
										updateWPSDetails,
										getEmployeeById,
										accountType,
										bankOptions,
										payrollClaims,
										mainProfile: history?.location?.state?.mainProfile,
										setNavigateTabCheck,
										employeeDetailState,
										setEmployeeDetailState,
										additions,
										setAdditions,
										deductions,
										setDeductions,
										gratuity,
										setGratuity,
										runEmployeeAllowanceList,
										setRunEmployeeAllowanceList,
										notification,
										setNotification,
										employeeAllowance,
										setEmpolyeeAllowance,
										empolyeeSalary,
										setEmpolyeeSalary,
										termiation,
										setTerminationType,
										overTimes,
										setOverTimes,
										month,
										setmonth,
										year,
										setyear,
										savePayrollValue,
										setSaveValue,
										gratutiycal,
										setGratuityCal,
										payrollDetails,
										setPayrollDetails,
										addAllowance,
										setAddAllowance,
										isEnabled,
										setIsEnabled,
										hrClaims,
										sampleData,
										setSampleData,
										leaveTypelist,
										setLeaveTypelist,
										buttonFlag,
										setButtonFlag,
										hasErrors,
										setHasErrors,
										empDocState,
										setEmpDocState,
										backupData,
										setBackupData,
										expiryDates,
										setExpiryDates,
										imageData,
										setImageData,
										empDocumentForm,
										employeeHistory,
										payrollTab,
										leavesTab,
										documentsTab,
										setAlertPopup,
										leavingDate,
										setLeavingDate,
										terminationId,
										setTerminationId,
									}}
								/>
							)}
						</div>
					</div>
				</div>
			)}

			{/* mobile */}
			{!isDataLoading && (
				<Row className="view-employees-container_mobile_view">
					<Col xs={24} sm={24}>
						<Row className="view-employees-india">
							<Col sm={24} xs={24}>
								<ImageComponent src={images.India} />
							</Col>
						</Row>
						<Row className="view-employees-header">
							<Col sm={17} xs={17} className="view-employee-col">
								<EdenredTypo>
									{employeeDetails?.firstName} {employeeDetails?.lastName}
								</EdenredTypo>
							</Col>
						</Row>
						<Row className="view-employees-content-id">
							<Col sm={12} xs={12} className="view-employee-id">
								<EdenredTypo>{employeeDetails?.employeeId}</EdenredTypo>
							</Col>
						</Row>
						<Row className="view-employees-content-date">
							<Col sm={18} xs={18} className="view-employee-number">
								<EdenredTypo>
									Date of Birth: {employeeDetails?.dateOfBirth} <br /> Mobile Number: {employeeDetails?.mobileNo || ''}
								</EdenredTypo>
							</Col>
						</Row>
						<Row className="view-employees-content-History">
							<Col sm={14} xs={14}>
								<EdenredButton type="outline-g" onClick={handleViewSalaryHistory}>
									<ImageComponent src={icons.not_focus.Show} />
									View Salary History
								</EdenredButton>
							</Col>
						</Row>
						<Row className="view-employees-green">
							<Col sm={24} xs={24} className={'view-employees-button'}>
								<EdenredTypo
									style={
										employeeDetails &&
										employeeDetails?.salaryChannelDescription &&
										employeeDetails?.salaryChannelDescription?.toLowerCase()?.indexOf('wps') >= 0
											? {}
											: { backgroundColor: '#e4dd3d', color: '#00344E' }
									}>
									{employeeDetails.salaryChannelDescription}
								</EdenredTypo>
							</Col>
						</Row>
						<Row className="view-employees-detail">
							{accountType !== accountTypeConst?.C3pay ? (
								<Row>
									<Col sm={24} xs={24} className="view-employees">
										<EdenredTypo>Card Details</EdenredTypo>
									</Col>
									<Col sm={24} xs={24} className="view-employees-issue">
										<EdenredTypo>IBAN: {employeeDetails?.iban}</EdenredTypo>
									</Col>
									<Col sm={24} xs={24} className="view-employees-issue">
										<EdenredTypo>Bank Name:{employeeDetails?.bankRoutingCode}</EdenredTypo>
									</Col>
								</Row>
							) : (
								<Row>
									<Col sm={24} xs={24} className="view-employees">
										<EdenredTypo>Card Details</EdenredTypo>
									</Col>
									<Col sm={24} xs={24} className="view-employees-issue">
										<EdenredTypo>Card Serial Number: {employeeDetails?.cardNo}</EdenredTypo>
									</Col>
									<Col sm={24} xs={24} className="view-employees-issue">
										<EdenredTypo>Card Issued: </EdenredTypo>
									</Col>
									<Col sm={24} xs={24} className="view-employees-issue">
										<EdenredTypo>Account Number: {employeeDetails?.accountNo}</EdenredTypo>
									</Col>
								</Row>
							)}
							<Col xs={24} sm={24}>
								{cardcontent?.map((content, index) => {
									if (accountType === accountTypeConst?.C3pay) {
										let disableClass = '';
										disableClass = currentSalaryChannel === salaryChannelConst?.Active ? '' : 'disabled';
										return (
											<Col
												key={index}
												style={{ display: content.label === 'Request Card Statement' ? 'none' : null }}
												className="view-employee-card-padding"
												onClick={() => !disableClass && handleClick(content)}>
												<EdenredEmployeesCard
													type="ViewEmployeeCardMobile"
													className={`view-card ${disableClass}`}
													content={content}
												/>
											</Col>
										);
									}
								})}
							</Col>
						</Row>
						<Col xs={24} sm={24} onClick={() => handleDocument(wpsDetails)}>
							<EdenredEmployeesCard type="NormalDetail" className="normal-card" content={wpsDetails} />
						</Col>
						<Col xs={24} sm={24}>
							<EdenredEmployeesCard type="EmployeeDocument" className="normal-card" content={employeeDocument} />
						</Col>
						{accountType === accountTypeConst?.C3pay ? (
							currentSalaryChannel === salaryChannelConst?.Active ? (
								<Row className="view-request-button">
									<Col xs={22} sm={22}>
										<EdenredButton type="outline-g" onClick={handleRequestCardholder}>
											<ImageComponent src={icons.not_focus.CardStatement} />
											Request Cardholder Statements
										</EdenredButton>
									</Col>
								</Row>
							) : (
								<Row className="view-request-button">
									<Col xs={22} sm={22} style={{ opacity: '0.5', boxShadow: '0px 5px 10px rgb(220 224 224 / 25%)' }}>
										<EdenredButton type="outline-g">
											<ImageComponent src={icons.not_focus.CardStatement} />
											Request Cardholder Statements
										</EdenredButton>
									</Col>
								</Row>
							)
						) : null}
						<Row className="view-delete">
							{SHOW_DELETE && (
								<Fragment>
									{/* {enableFooter && */}
									{employeeDetails?.salaryChannel === 'CREATINGACCOUNTNO' ? null : (
										<Col xs={24} sm={24} onClick={openDeleteModal}>
											<EdenredTypo>Delete Employee</EdenredTypo>
										</Col>
									)}
								</Fragment>
							)}
						</Row>
					</Col>
				</Row>
			)}
			<EmployeeProfilePopup
				{...{
					handleAlertPopup,
					alertPopup,
					employeeProfilePopup,
					handleC3PayToBankAccount,
					confirmationModelOpen,
					afterConfirmationOpenModal,
					confirmationsRequest,
					replacementCardMessage,
					employeeCardAddress,
					modalOpen,
					afterOpenModal,
					getDeleterole,
					cardloading,
					handleInviteEmployeeToPortal,
					setInvitePortalEmailId,
					invitePortalEmailId,
					employeeDetails,
					loading,
				}}
			/>
		</div>
	);
};
export default ViewEmployeePresentational;
